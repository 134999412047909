import logger from 'services/logger/logger';

const useMocks = process?.env?.USE_MOCKS === 'true';

const config = {
  rest: {
    host: process?.env?.API_REST_HOST,
    api_key: process?.env?.API_REST_KEY,
    apiGateway: process?.env?.API_REST_GATEWAY,
    visitorPassApiGateway: process?.env?.VISITOR_PASS_API_REST_GATEWAY,
    lfaService: process?.env?.LFA_REST_HOST,
    clientBucketName: process?.env?.CLIENT_S3_BUCKET
  },
  sockets: {
    host: process?.env?.API_SOCKET_HOST,
    api_key: process?.env?.API_SOCKET_KEY
  },
  visitorBatchUploadBucket: process?.env?.BATCH_UPLOAD_BUCKET,
  localization: {
    provide_device_id_and_token: 'Please provide the deviceId and token',
    invalid_session: 'Session does not exist',
    invalid_token: 'Invalid Token',
    no_device: 'Could not find device',

    unknown_error: 'An unknown error has occurred',
    system_error:
      'A system error occurred, please contact the system administrator',
    not_logged_in: 'You must be logged in to perform this action',
    provide_id: 'Please provide an ID',
    provide_building_id: 'Please provide a valid building_id',
    provide_company_id: 'Please provide a valid company_id',
    delete_no_permission:
      'Record does not exist or you do not have permission to delete it',
    export_no_records: 'No available records to download',
    update_no_permission:
      'Record does not exist or you do not have permission to update it',
    no_permission: 'You do not have permission to access this',
    no_records: 'No records found',
    no_changes: 'Record is the same, no changes have been made',
    invalid_company_id: 'Please provide a valid company',
    invalid_building_id: 'Please provide a valid building',
    provide_fields: 'Missing Fields',
    valid_name: 'Please provide a valid name',
    duplicate_name: 'An record with the same name already exists',
    duplicate_record: 'A record with the same name already exists',
    provide_permission: 'Please provide a permission to check',
    onboarding_invalid_session_id: 'Please provide a valid session Id',
    onboarding_invalid_session: 'Please restart onboarding!',
    onboarding_invalid_data:
      'There has been a system error, please contact support',
    onboarding_provide_data_key: "You've not provided a key",
    onboarding_provide_data_value: 'Please provide a valid value',
    onboarding_no_data_found: "We couldn't find what you were looking for",
    onboarding_provide_email_or_password:
      'Please provide an e-mail address and password',
    onboarding_missing_fields: 'This on-boarding session has missing fields',
    onboarding_provide_email: 'Please provide a valid e-mail',
    onboarding_provide_password: 'Please provide a valid password',
    onboarding_provide_company_id: 'Please select a valid company',
    onboarding_invalid_company_id: 'Please select a valid company',
    onboarding_email_duplicate:
      'Sorry this e-mail address is already registered',
    onboarding_email_invalid: 'Please provide a valid e-mail address',
    onboarding_email_company_invalid:
      "The e-mail you've provided is not valid for your company",
    invalid_pin: 'You have entered the wrong code',
    invalid_token_or_pin: 'You have waited too long and your code has expired',
    new_pin_required: 'A new code is required and needs to be requested',
    login_no_email_or_password: 'Please provide an e-mail address and password',
    login_provide_device_secret: 'Please provide a device secret',
    login_invalid_email: 'Please provide a valid email',
    login_invalid_password: 'Password must be at least 3 characters',
    login_invalid_email_or_password: 'Invalid credentials, please try again',
    login_disabled_account_no_roles:
      'This account does not have permission to access the system, please contact your building administrator',
    login_disabled_account:
      'Account disabled, please contact your building administrator.',
    user_provide_email_or_password:
      'Please provide an e-mail address and password',
    user_provide_name: 'Please provide a name for this user',
    user_company_link_wrong:
      'To link this user to a company, please provide building_id and company_id',
    user_valid_email: 'Please provide a valid email',
    user_valid_password: 'Please provide a valid password',
    user_valid_company_id: 'Please provide a valid companyId',
    user_duplicate_email:
      'A user with that e-mail address already exists in our database',
    user_email_company_invalid:
      "The e-mail provided is not correct for the company you've selected",
    user_provide_roles: 'Please provide an array of roles',
    role_provide_name: 'Please provide a name',
    role_provide_name_description: 'Please provide a name or description',
    role_update_permissions_provide_role: 'Please provide a role',
    visitor_no_permission:
      'Visitor does not exist or you do not have permission to update them',
    visitor_checked_in: 'Visitor already checked in',
    visitor_checked_out: 'Visitor already checked out',
    visitor_type_valid_name: 'Please provide a valid name',
    visitor_type_duplicate_name: 'A type with the same name already exists',
    visitor_type_no_permission:
      'Type does not exist or you do not have permission to update it',

    notification_valid_title: 'Please provide a valid title',
    notification_valid_description: 'Please provide a valid description',
    notification_valid_recipient:
      'A valid user_id, company_id or building_id are required',
    notification_valid_schedule_date: 'Please provide a valid schedule_date',
    notification_no_send_permission:
      "You don't have permission to send this notification",

    email_template_valid_name: 'Please provide a valid name',
    email_template_valid_subject: 'Please provide a valid subject',
    email_template_exists: 'A template with that name already exists',
    email_template_no_permission:
      "That template does not exist or you don't have permission to update it",

    invalid_name: "The name you've provided isn't valid",
    invalid_floor_name:
      "The name you've provided isn't valid, it must be at least 3 characters long",
    invalid_job_role_name:
      "The name you've provided isn't valid, it must be at least 5 characters long",
    invalid_location_name:
      "The name you've provided isn't valid, it must be at least 3 characters long",
    invalid_travel_option_name:
      "The name you've provided isn't valid, it must be at least 5 characters long",
    invalid_bay_name:
      "The name you've provided isn't valid, it must be at least 5 characters long",
    invalid_visitor_type_name:
      "The name you've provided isn't valid, it must be at least 3 characters long",

    helpdesk_valid_attending_date:
      'Please provide a valid attending date in the future',

    delivery_bay_in_use: "Sorry that bay is in use at the time you've selected",
    delivery_max_duration: 'Sorry that bay is not available at this time',
    permits_invalid_session_id: 'Please provide a valid permit session id',
    permits_provide_job_details:
      "This session doesn't have any job details saved against it",
    permits_provide_contractor_details:
      "This session doesn't have any contractor details saved against it",
    permits_invalid_id: "That permit doesn't exist",
    permits_invalid_section_id: 'Please provide a valid section id',
    permits_provide_data: "Please provide values you'd like to save",
    permits_invalid: "The session or permit id you've provided is not valid",
    permits_invalid_data: "The data you've tried to save is not valid",
    permit_already_approved:
      "This permit has already been approved and can't be edited",
    valid_description: 'Description requires a minimum of 3 characters',
    delivery_valid_delivery_for:
      'Delivery For requires a minimum of 3 characters',
    delivery_valid_haulier_name:
      'Haulier Name requires a minimum of 3 characters',
    delivery_valid_additional_details:
      'Additional Details requires a minimum of 3 characters',
    delivery_valid_creator_name:
      'Creator Name requires a minimum of 3 characters',

    building_terms_provide_type:
      'Internal error, please contact the administrator'
  }
};

if (useMocks) {
  config.mock = {
    permissions: {
      'users/search': true,
      'users/view': true,
      'users/create': true,
      'users/update': true,
      'users/archive': true,
      'users/delete': true,
      'users/disable': true,
      'users/export': true,
      'users/change_role': true,

      'users/roles/manage': true,
      'users/roles/search': true,
      'users/roles/create': true,
      'users/roles/update': true,
      'users/roles/delete': true,

      'occupiers/search': true,
      'occupiers/view': true,
      'occupiers/create': true,
      'occupiers/update': true,
      'occupiers/archive': true,
      'occupiers/disable': true,

      'visitors/search': true,
      'visitors/view': true,
      'visitors/pass/export': true,
      'visitors/create': true,
      'visitors/create/bulk': true,
      'visitors/update': true,
      'visitors/archive': true,
      'visitors/unscheduled': true,
      'visitors/checkin': true,
      'visitors/checkout': true,
      'visitors/export': true,
      'visitors/occupiers': true,

      'deliveries/search': true,
      'deliveries/view': true,
      'deliveries/create': true,
      'deliveries/update': true,
      'deliveries/archive': true,
      'deliveries/unscheduled': true,
      'deliveries/approve': true,
      'deliveries/checkin': true,
      'deliveries/checkout': true,
      'deliveries/export': true,

      'helpdesk/search': true,
      'helpdesk/view': true,
      'helpdesk/create': true,
      'helpdesk/update': true,
      'helpdesk/archive': true,
      'helpdesk/assign': true,
      'helpdesk/close': true,
      'helpdesk/comment': true,
      'helpdesk/export': true,

      'permits/search': true,
      'permits/view': true,
      'permits/create': true,
      'permits/update': true,
      'permits/archive': true,
      'permits/approve': true,
      'permits/activate': true,
      'permits/deactivate': true,
      'permits/close': true,
      'permits/export': true,

      'lfa/search': true,
      'lfa/view': true,
      'lfa/create': true,
      'lfa/edit': true,
      'lfa/delete': true,
      'lfa/archive': true,
      'lfa/export': true,

      'lfa/comment/create': true,
      'lfa/comment/search': true,

      'lfa/terms/search': true,
      'lfa/terms/view': true,
      'lfa/terms/create': true,
      'lfa/terms/edit': true,
      'lfa/terms/delete': true,

      'lfa/guidance/search': true,
      'lfa/guidance/view': true,
      'lfa/guidance/create': true,
      'lfa/guidance/edit': true,
      'lfa/guidance/delete': true,

      'building/manage': true,
      'building/address': true,
      'building/floors/create': true,
      'building/floors/search': true,
      'building/floors/update': true,
      'building/floors/disable': true,
      'building/floors/delete': true,

      'building/locations/create': true,
      'building/locations/search': true,
      'building/locations/update': true,
      'building/locations/disable': true,
      'building/locations/delete': true,

      'building/bays/create': true,
      'building/bays/search': true,
      'building/bays/update': true,
      'building/bays/disable': true,
      'building/bays/delete': true,

      'building/job_roles/create': true,
      'building/job_roles/search': true,
      'building/job_roles/update': true,
      'building/job_roles/disable': true,
      'building/job_roles/delete': true,

      'building/contact_types/create': true,
      'building/contact_types/search': true,
      'building/contact_types/update': true,
      'building/contact_types/disable': true,
      'building/contact_types/delete': true,

      'building/departments/create': true,
      'building/departments/search': true,
      'building/departments/update': true,
      'building/departments/disable': true,
      'building/departments/delete': true,

      'building/email_templates/create': true,
      'building/email_templates/search': true,
      'building/email_templates/update': true,
      'building/email_templates/disable': true,
      'building/email_templates/delete': true,

      'building/visitor_types/create': true,
      'building/visitor_types/search': true,
      'building/visitor_types/update': true,
      'building/visitor_types/disable': true,
      'building/visitor_types/delete': true,

      'building/priorities/create': true,
      'building/priorities/search': true,
      'building/priorities/update': true,
      'building/priorities/disable': true,
      'building/priorities/delete': true,

      'helpdesk/categories/create': true,
      'helpdesk/categories/search': true,
      'helpdesk/categories/update': true,
      'helpdesk/categories/disable': true,
      'helpdesk/categories/delete': true,

      'travel_options/search': true,
      'travel_options/update': true,
      'travel_options/disable': true,
      'travel_options/delete': true,

      'companies/search': true,
      'companies/view': true,
      'companies/create': true,
      'companies/update': true,
      'companies/archive': true,
      'companies/delete': true,
      'companies/disable': true,
      'companies/export': true,
      'companies/domains': true,

      'doc-store/upload': true,
      'doc-store/download': true,
      'doc-store/file/delete': true,
      'doc-store/folder/create': true,
      'doc-store/folder/delete': true,
      'doc-store/folder/edit-name': true,
      'doc-store/search': true,
      'doc-store/move': true,

      'buildings/search': true,
      'buildings/view': true,
      'buildings/create': true,
      'buildings/update': true,
      'buildings/archive': true,
      'buildings/delete': true,
      'buildings/disable': true,
      'buildings/export': true,

      'mobile/manage': true,

      'mobile/articles/manage': true,
      'mobile/articles/search': true,
      'mobile/articles/view': true,
      'mobile/articles/create': true,
      'mobile/articles/update': true,
      'mobile/articles/archive': true,
      'mobile/articles/delete': true,

      'mobile/polls/manage': true,
      'mobile/polls/search': true,
      'mobile/polls/view': true,
      'mobile/polls/create': true,
      'mobile/polls/update': true,
      'mobile/polls/archive': true,
      'mobile/polls/delete': true,
      'mobile/polls/disable': true,
      'mobile/polls/export': true,

      'mobile/my_building/manage': true,
      'mobile/my_building/search': true,
      'mobile/my_building/view': true,
      'mobile/my_building/create': true,
      'mobile/my_building/update': true,
      'mobile/my_building/archive': true,
      'mobile/my_building/delete': true,

      'mobile/events/manage': true,
      'mobile/events/search': true,
      'mobile/events/view': true,
      'mobile/events/create': true,
      'mobile/events/update': true,
      'mobile/events/archive': true,
      'mobile/events/delete': true,
      'mobile/events/disable': true,
      'mobile/events/export': true,

      'mobile/offers/manage': true,
      'mobile/offers/search': true,
      'mobile/offers/view': true,
      'mobile/offers/create': true,
      'mobile/offers/update': true,
      'mobile/offers/archive': true,
      'mobile/offers/delete': true,
      'mobile/offers/disable': true,
      'mobile/offers/export': true,

      'mobile/classes/manage': false,
      'mobile/treatments/manage': false,
      'mobile/digital-id/manage': false,
      'mobile/access/manage': false,

      'mobile/support/manage': false,
      'mobile/journeys/manage': false,
      'mobile/meeting-rooms/manage': false,
      'mobile/neighbours/manage': false,

      'mobile/notifications/manage': true,
      'mobile/notifications/search': true,
      'mobile/notifications/create': true,
      'mobile/notifications/clone': true,
      'mobile/notifications/view': true,

      'mobile/retailers/manage': false,
      'mobile/settings/manage': false,
      'mobile/travel/manage': false,
      'mobile/visitors/manage': false,
      'mobile/weather/manage': false,

      'platform/manage': true
    },
    helpers: {
      get: (key) => {
        let value = localStorage.getItem(key);
        if (value !== null) {
          try {
            value = JSON.parse(value);
          } catch (e) {
            logger.debug('Mocks::Get::JSON.Parse Error', key);
          }
        }
        return value;
      },
      save: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
      }
    }
  };
}
export default config;
