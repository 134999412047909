/* eslint-disable react/jsx-props-no-spreading */
import React, { createContext, useContext, useState, useEffect } from 'react';

import logger from 'services/logger/logger';

import { apiInstance } from '../services';
import { useAuth } from './auth-context';

const APIService = apiInstance();
const BuildingContext = createContext();

const BuildingProvider = (props) => {
  const [activeBuilding, setActiveBuilding] = useState();
  const [isBuildingMigrated, setIsBuildingMigrated] = useState(0);
  const [buildingData, setBuildingData] = useState(null);
  const [buildings, setBuildings] = useState([]);
  const [occupierData, setOccupierData] = useState([]);
  const { loggedIn } = useAuth();

  useEffect(() => {
    if (!loggedIn) {
      setBuildingData(null);
      setActiveBuilding(null);
      setBuildings([]);
    }
  }, [loggedIn]);

  const updateBuldingEntity = (name, entity) => {
    const copiedEntity = Object.assign(
      Object.create(Object.getPrototypeOf(buildingData)),
      buildingData,
      { [name]: entity }
    );
    setBuildingData(copiedEntity);
  };

  const setBuilding = (id) => {
    const {
      namespaces: { buildings: buildingsService }
    } = APIService;

    return new Promise(async (resolve) => {
      try {
        if (!buildingData || buildingData?.id !== id) {
          const building = await buildingsService.mine(id);
          // Todo: Replace later, when backend is fixed
          if (Array.isArray(building?.occupiers)) {
            building.occupiers.forEach(
              (occupier) => (occupier.building = building)
            );
          }

          setOccupierData(building?.occupiers);
          setBuildingData(building);
          setIsBuildingMigrated(building?.migrated_to_v2 || 0);
          setActiveBuilding(id);
          resolve(building);
        } else {
          resolve(buildingData);
        }
      } catch (e) {
        logger.debug(e);
        resolve(null);
      }
    });
  };

  const setBuildingByName = (name) => {
    const id = parseInt(
      name
        .split('-')
        .pop()
        .replace(/[^0-9]+/i, ''),
      10
    );
    return setBuilding(id);
  };

  const buildingTermsAndConditions = (type = 'registration') => {
    if (
      buildingData &&
      Object.hasOwnProperty.call(buildingData, 'terms_and_conditions')
    ) {
      const { terms_and_conditions } = buildingData;

      if (Object.hasOwnProperty.call(terms_and_conditions, type)) {
        return terms_and_conditions[type];
      }
    }
    return null;
  };

  return (
    <BuildingContext.Provider
      value={{
        buildings,
        setBuildings,
        setActiveBuildingByName: setBuildingByName,
        setActiveBuilding: setBuilding,
        isBuildingMigrated,
        clearBuildingData: () => setBuildingData(null),
        updateBuldingEntity,
        activeBuilding,
        buildingData,
        occupierData,
        buildingTermsAndConditions,
        getURIPath: () => buildingData.getURIPath()
      }}
      {...props}
    />
  );
};

const useBuilding = () => useContext(BuildingContext);

export { BuildingProvider, useBuilding };
